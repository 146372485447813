import { Component, createContext, useContext } from "react";
import { useNavigate } from "react-router";
import {
  auth,
  createUser,
  getUserData,
  getUserDataNew,
  UploadUserYear,
} from "../firebase";
import {
  createInitialUserAccount,
  uploadUserData,
} from "../functions/accountFunctions";
import {
  checkIfTeacherHasSchool,
  checkIfUserSubscribed,
} from "../functions/schoolFunctions";

export const UserContext = createContext({
  setYear: (year) => {},
  resetSchool: () => {},
  resetSubscription: () => {},
  data: null,
  user: null,
  data2: {},
  hasSchool: false,
  hasSubscription: false,
  loaded: false,
});

class UserProvider extends Component {
  state = {
    data: null,
    data2: {},
    user: null,
    hasSchool: false,
    hasSubscription: false,
    loaded: false
  };

  setYear = (year) => {
    this.setState({
      data2: {
        ...this.state.data2,
        Year: parseInt(year),
      },
    });
    uploadUserData(this.state.data2.Guid, { Year: parseInt(year) });
  };

  resetSchool = async () => {
    let data = this.state.data2;
    let school = await checkIfTeacherHasSchool(data.Guid, data.SchoolID);
    this.setState({
      data: {
        ...this.state.data,
        hasSchool: school,
      },
    });
  };

  resetSubscription = async () => {
    let data = this.state.data2;
    let subscription = await checkIfUserSubscribed(data.Guid, data.SchoolID);

    this.setState({
      data: {
        ...this.state.data,
        hasSubscription: subscription,
      },
    });
  };

  componentDidMount = () => {
    auth.onAuthStateChanged(async (userAuth) => {
      let data = null;
      let data2 = {};
      let school = false;
      let subscribed = false;
      let loaded = false;
      this.setState({ user: userAuth });
      if (userAuth) {
        data = await getUserData(userAuth.uid);
        data2 = await getUserDataNew(userAuth.uid);
        if (data == null) {
          data = await createUser(userAuth);
        }

        if (data2 === undefined || Object.keys(data2).length === 0) {
          data2 = await createInitialUserAccount(userAuth);
          if (data2 === false){
            data2 = await getUserDataNew(userAuth.uid);
          }
        } else {
          const schoolID = data2.SchoolID;
          const userID = data2.Guid;
          school = await checkIfTeacherHasSchool(userID, schoolID);
          subscribed = await checkIfUserSubscribed(userID, schoolID);
        }
        loaded = true
      }
      this.setState({
        data: data,
        data2: data2,
        hasSchool: school,
        hasSubscription: subscribed,
        loaded: loaded,
      });
    });
  };

  render() {
    return (
      <UserContext.Provider
        value={{
          setYear: this.setYear,
          loaded: this.state.loaded,
          data: this.state.data,
          data2: this.state.data2,
          hasSchool: this.state.hasSchool,
          hasSubscription: this.state.hasSubscription,
          resetSchool: this.resetSchool,
          resetSubscription: this.resetSubscription,
        }}
      >
        {this.props.children}
      </UserContext.Provider>
    );
  }
}
export default UserProvider;

export const useAuth = () => {
  return useContext(UserContext);
};
