import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  updateDoc,
  deleteDoc,
  setDoc,
} from "firebase/firestore";

import { httpsCallable } from "firebase/functions";

import { firestoreDb, functions } from "../firebase";
import { getEmptyAccount } from "../models/account";

export async function getUserData(id) {
  try {
    const q = query(doc(firestoreDb, `users/${id}`));
    let snapshot = await getDoc(q);
    let details = snapshot.data();
    return details;
  } catch (e) {
    console.log(e);
    return {};
  }
}

export async function uploadUserData(id, data) {
  try {
    const q = query(doc(firestoreDb, `users/${id}`));
    await updateDoc(q, data);
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function createInitialUserAccount(firebaseData) {
  try {
    if (firebaseData === null) {
      return false;
    }
    let account = getEmptyAccount();
    const uid = firebaseData.uid;
    const email = firebaseData.email;
    const creationDate = firebaseData.metadata.creationTime

    const userDocRef = doc(firestoreDb, `users/${uid}`);
    const userDocSnapshot = await getDoc(userDocRef);

    // Check if the user document already exists
    if (userDocSnapshot.exists()) {
      console.log("User account already exists");
      return false;
    }

    
    account = {
      ...account,
      Guid: uid,
      Email: email,
      CreationDate: creationDate
    };
    const q = query(doc(firestoreDb, `users/${uid}`));
    await setDoc(q, account);
    return account;
  } catch (e) {
    console.log(e);
    return false;
  }
}


